import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="not-found ">
      <h1>Page Introuvable</h1>
      <p>Vous avez entr&eacute; une page introuvable</p>
    </div>
  </Layout>
)

export default NotFoundPage
